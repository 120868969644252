//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { account_service } from "@/services";
import BaseLayout from "@/layouts/Base";
export default {
  metaInfo: {
    title: "Reset Password",
  },
  components: { BaseLayout },
  data() {
    return {
      reset_data: {
        password: "",
        cnf_password: "",
      },
      reset_error: {
        password: "",
        cnf_password: "",
      },
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    clear_error() {
      this.error_msg = "";
      this.reset_error.password = "";
      this.reset_error.cnf_password = "";
    },
    submit() {
      this.clear_error();
      account_service
        .post(this.reset_data, `${this.token}/reset`)
        .then(() => {
          this.$q.notify({
            message: "Your password has been changed successfully!",
            color: "positive",
            icon: "mdi-check",
            textColor: "white",
          });
          this.$router.push({ name: "login", query: { next: "home" } });
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data) {
            if (
              this.check_fields_for_error(
                error.response.data,
                this.reset_error,
                ["password", "cnf_password"]
              )
            ) {
              this.error_msg = "Please fix the error(s) above to continue";
            }
          }
          if (!this.error_msg)
            this.error_msg = this.decode_error_message(error);
        });
    },
  },
};
